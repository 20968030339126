<template>
  <div class="successContainer" style="padding-left: 10px">
    <h4>恭喜您完成注册！</h4>
    <h4>开启您在谕学的学习之旅吧！</h4>
  </div>
</template>

<script>
export default {
  name: 'RegisterStepFour'
}
</script>

<style scoped>
.successContainer{
  padding: 60px 0;
  text-align: center;
  font-size: 24px;
}
</style>
